import { createSlice } from '@reduxjs/toolkit';
import { baseUserDetailPostReducers, baseUserDetailPostState, } from '../../../services/detail-post-service/user/reducer';
import { DETAIL_BIRTHDAY_POST_ROOT_SERVICES_REDUCER_NAMESPACES } from '../constants';
var userDetailBirthdayPostServiceState = createSlice({
    name: DETAIL_BIRTHDAY_POST_ROOT_SERVICES_REDUCER_NAMESPACES.userDetailPost,
    initialState: baseUserDetailPostState,
    reducers: baseUserDetailPostReducers,
});
export var actions = userDetailBirthdayPostServiceState.actions;
export default userDetailBirthdayPostServiceState.reducer;
